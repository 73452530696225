import React from 'react'
import { GifEmoji, Ellipse, Title } from '@components'
import * as styles from '@css/home_section.module.css'
import { useContent } from '@contexts'
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'

export const HomeSection = () => {
  const content = useContent()
  const home = content?.home || {}

  const staticImagePropsAnimationBounceImageUp = {
    className:
      'flex-grow-0 flex-shrink-0 w-50-sub-margin md:w-25-sub-margin rounded-3xl mt-1 shadow-lg-grey animate-bounce-image-up mx-1 md:mx-2',
    alt: 'sampleImage',
    placeholder: 'blurred',
  }

  const staticImagePropsAnimationBounceImageDown = {
    className:
      'flex-grow-0 flex-shrink-0 w-50-sub-margin md:w-25-sub-margin rounded-3xl mt-1 shadow-lg-grey animate-bounce-image-down mx-1 md:mx-2',
    alt: 'sampleImage',
    placeholder: 'blurred',
  }

  const staticImageProps = {
    alt: 'sampleImage',
    className: 'block mx-auto rounded-xl',
    loading: 'eager',
  }

  return (
    <section>
      <Ellipse />
      <Title>
        {Highlight => (
          <>
            {home.title?.text}
            <Highlight>{home.title?.highlighted}</Highlight>
            {home.title?.text2}
          </>
        )}
      </Title>
      <Swiper
        className="mt-8 home-swiper"
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        allowTouchMove={false}
        simulateTouch={false}
        slidesPerView={4}
        spaceBetween={20}
        slidesPerGroup={4}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 4,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 4,
          },
        }}
      >
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/boy4.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/girl36.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/boy3.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/girl21.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/boy2.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/girl55.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/boy1.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            src="../../../static/images/girl26.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
      </Swiper>
      {/* <div className="flex flex-wrap mt-8 md:mt-12">
        <StaticImage
          src="../../../static/images/sample1.jpg"
          {...staticImagePropsAnimationBounceImageUp}
        />
        <StaticImage
          src="../../../static/images/sample2.jpg"
          {...staticImagePropsAnimationBounceImageDown}
        />
        <StaticImage
          src="../../../static/images/sample3.jpg"
          {...staticImagePropsAnimationBounceImageUp}
        />
        <StaticImage
          src="../../../static/images/sample4.jpg"
          {...staticImagePropsAnimationBounceImageDown}
        />
      </div> */}
      <h2 className={styles.slogan}>
        {home.subText?.text1} <br />
        <strong>{home.subText?.text2}</strong>
      </h2>
    </section>
  )
}
