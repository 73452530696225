import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const GalleryImgs = ({classes}) => {

  return (
    <>
    <div 
     className={classes}
     data-sal="fade"
     data-sal-delay="300"
     data-sal-easing="linear"
    >
      <StaticImage  
        src="../../static/images/boy1.jpg"
        alt="General Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy2.jpg"
       alt="Thanos Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl1.jpg"
       alt="Thanos Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy3.jpg"
       alt="Cowboy Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl2.jpg"
       alt="Thanos Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy4.jpg"
       alt="Boy Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy5.jpeg"
       alt="Soldier Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy6.jpeg"
       alt="Woody Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy7.jpeg"
       alt="Knight Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy8.jpeg"
       alt="Pencil drawing Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy9.jpeg"
       alt="Superman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy10.jpeg"
       alt="Man in fur Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy11.jpeg"
       alt="Mario Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy12.jpeg"
       alt="Pirate Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy13.jpeg"
       alt="Gentleman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy14.jpeg"
       alt="Serious Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy15.jpeg"
       alt="Astronout Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy16.jpeg"
       alt="Soldier Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy17.jpeg"
       alt="Commandos Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy18.jpeg"
       alt="Parachute Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy19.jpeg"
       alt="Cartoon Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy20.jpeg"
       alt="Santa Clause Man Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl3.jpg"
       alt="Redness Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl4.jpg"
       alt="Magical Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl5.jpg"
       alt="Lady Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl6.jpg"
       alt="Worker Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl7.jpg"
       alt="Water Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl8.jpg"
       alt="Thanos Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl9.jpg"
       alt="Thanos Avatar" 
      />
    </div>

    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl10.jpg"
       alt="Thanos Avatar" 
      />
    </div>
    
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl11.jpg"
       alt="Thanos Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl12.jpg"
       alt="XMEN Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl13.jpg"
       alt="Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl14.jpg"
       alt="Green Yellow Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl15.jpg"
       alt="Modern Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl16.jpg"
       alt="Woman Helmet Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl17.jpg"
       alt="Queen Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl18.jpg"
       alt="Greek Goddess Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl19.jpg"
       alt="Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl20.jpg"
       alt="Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl21.jpg"
       alt="Woman in Red Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl22.jpg"
       alt="Glass Water Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}

    >
     <StaticImage  
       src="../../static/images/girl23.jpg"
       alt="Future Anime Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl24.jpg"
       alt="Blone Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl25.jpg"
       alt="3D Girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl26.jpg"
       alt="Pretty Girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl27.jpg"
       alt="Colorful Hair Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl28.jpg"
       alt="Angel Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl29.jpg"
       alt="Hand drawing Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl30.jpg"
       alt="Black and White Red lips Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl31.jpg"
       alt="Girl in jeans Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl32.jpg"
       alt="Woman Coat Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl33.jpg"
       alt="Wedding Dress Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl34.jpg"
       alt="Portrait Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl35.jpg"
       alt="Pretty Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl36.jpg"
       alt="Anime Girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl37.jpg"
       alt="Lost Princess Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl38.jpg"
       alt="Colorful Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl39.jpg"
       alt="Queen Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl40.jpg"
       alt="Goddess Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl41.jpg"
       alt="Candle Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl42.jpg"
       alt="Greens Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl43.jpg"
       alt="Santa Clause Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl44.jpg"
       alt="Woman In Roses Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl45.jpg"
       alt="Princess Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl46.jpg"
       alt="Red lips Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl47.jpg"
       alt="Beats Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl48.jpg"
       alt="Light Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl49.jpg"
       alt="Sales Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl50.jpg"
       alt="Red Hair Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl51.jpg"
       alt="Underwater Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl52.jpg"
       alt="Redhead Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl53.jpg"
       alt="Lady Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl54.jpg"
       alt="Old Image Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl55.jpg"
       alt="Warrior Anime Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl56.jpg"
       alt="Pink hair Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl57.jpg"
       alt="Music Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl58.jpg"
       alt="Girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl59.jpg"
       alt="Fire Woman Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl60.jpg"
       alt="Queen Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl61.jpg"
       alt="Folk Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl62.jpg"
       alt="Horror Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl63.jpg"
       alt="Bikini Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl64.jpg"
       alt="Red dress Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl65.jpg"
       alt="Dreamer Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl66.jpg"
       alt="Roses Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl67.jpeg"
       alt="Masked Warrior Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl68.jpeg"
       alt="Pirate girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl68.jpeg"
       alt="Pirate girl Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl69.jpg"
       alt="Oil painting Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl70.jpg"
       alt="Oil painting princess Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl71.jpg"
       alt="Girl in the sky between the stars Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl72.jpg"
       alt="Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/girl73.jpg"
       alt="Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy21.jpg"
       alt="Boy in suite Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy22.jpg"
       alt="Cowboy Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy23.jpg"
       alt="Police office Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy24.jpg"
       alt="God of war Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy25.jpg"
       alt="Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy26.jpg"
       alt="Portrait Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy27.jpg"
       alt="The Hulk Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy28.jpg"
       alt="Pencil painting Avatar" 
      />
    </div>
    <div 
     className={classes}
     data-sal="fade"   
     data-sal-delay="100"
     data-sal-easing="linear"
    >
     <StaticImage  
       src="../../static/images/boy29.jpg"
       alt="Spider man Avatar" 
      />
    </div>
    </>
  )
}
