import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useEffect } from 'react'
import classNames from 'classnames'

export const Navbar = () => {
  const [stickyHeader, setStickyHeader] = useState(false)

  useEffect(() => {
    const handleOnScroll = () => {
      if (window.scrollY > 100) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    window.addEventListener('scroll', handleOnScroll)
    return () => {
      window.removeEventListener('scroll', handleOnScroll, true)
    }
  }, [])

  return (
    <div className={classNames('py-4 w-full transition duration-150', {
      'sticky top-0 bg-white z-20': stickyHeader
    })}>
      <div className="container flex items-start justify-between w-11/12 mx-auto max-w-7xl">
        <div className="z-0 w-12">
          <StaticImage
            src="../../../static/logo.png"
            alt="Coolpic.io logo"
            className="rounded-lg"
          />
        </div>
        <a
          href={process.env.REACT_APP_LOGIN_LINK}
          className="flex items-center px-8 py-2 text-purple-500 transition duration-150 border border-purple-500 rounded-full hover:bg-purple-500 hover:text-white"
        >
          Sign in
        </a>
      </div>
    </div>
  )
}
