import * as React from 'react'

import { Seo } from '@components'
import { Layout } from '../components'

import {
  Navbar,
  Faq,
  Work,
  HomeSection,
  WhyCoolPic,
  Solutions,
  Pricing,
  HowItWorks,
  SpotsCounter,
  Footer,
} from '../components/Sections'

import { SharedImagesProvider, ContentProvider } from '@contexts'
import { Gallery } from '../components/Sections/Gallery'

const IndexPage = ({ pageContext }) => {
  return (
    <div>
      <Navbar />
      <Layout>
        <SharedImagesProvider>
          <ContentProvider content={pageContext.content}>
            <HomeSection />
            <WhyCoolPic />
            {/* <Solutions /> */}
            <HowItWorks />
            {/* <Work /> */}
            <Pricing />
            {/* <SpotsCounter /> */}
            {/* <Faq /> */}
            <Gallery />
            <Footer />
          </ContentProvider>
        </SharedImagesProvider>
      </Layout>
    </div>
  )
}

export const Head = () => (
  <Seo title="Coolpic - See yourself as you've never seen before." />
)

export default IndexPage
