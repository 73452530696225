import React from 'react'
import { Title, TextSlider } from '@components'
import { TextIcon } from '../TextIcon'
import * as styles from '@css/why_acquire_leads.module.css'
import { useContent } from '@contexts'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { StaticImage } from 'gatsby-plugin-image'

export const WhyCoolPic = () => {
  const content = useContent()
  const why = content?.why || {}

  const staticImageProps = {
    alt: 'sampleImage',
    className: 'block mx-auto rounded-xl',
    loading: 'eager',
  }

  return (
    <section className={styles.section}>
      {why.title && (
        <Title className={styles.title}>
          {Highlight => (
            <>
              {why.title?.text}
              <Highlight>{why.title?.highlighted}</Highlight>
              <br />
              {why.title?.text2}
            </>
          )}
        </Title>
      )}
      {why.text1 && <p className="large-centered-text">{why.text1}</p>}
      {why.text2 && <p className="large-centered-text">{why.text2}</p>}
      {why.text3 && <p className="large-centered-text">{why.text3}</p>}
      {/* <Title>{H => <>A super hero? cowboy? disney character?</>}</Title> */}
      {/* <div className="flex items-center justify-center w-full mb-6 text-5xl sm:mb-0">
        <TextSlider
          items={[
            'You as a Pixar Character?',
            'You as a Superhero?',
            'You as a Firefighter?',
            'You as a Pirate?',
            'You as Floreal Princess?',
            'You as Santa Clause?',
          ]}
        />
      </div> */}
      <Swiper
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 1400,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        allowTouchMove={false}
        simulateTouch={false}
        slidesPerView={1}
        style={{
          maxWidth: '550px',
          width: '100%',
        }}
        className="mb-2 md:mt-4 md:mb-4 rounded-xl"
        centeredSlides
      >
        <SwiperSlide>
          <h1 className={styles.text}>You as a Pixar Character?</h1>
          <StaticImage
            className="mt-12"
            src="../../../static/images/girl36.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h1 className={styles.text}>You as a Superhero?</h1>
          <StaticImage
            src="../../../static/images/girl4.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h1 className={styles.text}>You as a Firefighter?</h1>
          <StaticImage
            src="../../../static/images/girl6.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h1 className={styles.text}>You as a Pirate?</h1>
          <StaticImage
            src="../../../static/images/girl68.jpeg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h1 className={styles.text}>You as Floreal Princess?</h1>
          <StaticImage
            src="../../../static/images/girl66.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
        <SwiperSlide>
          <h1 className={styles.text}>You as Santa Clause?</h1>
          <StaticImage
            src="../../../static/images/girl64.jpg"
            {...staticImageProps}
          />
        </SwiperSlide>
      </Swiper>
      <h3 className="mt-4 text-xl font-bold md:text-4xl">No Problem!</h3>
      {/* <div className={classNames('flex-layout mb-6', styles.useCasesWrapper)}>
        {why.items?.map(item => (
          <TextIcon key={item.title} title={item.title}>
            {item.text}
          </TextIcon>
        ))}
      </div> */}
    </section>
  )
}
