import React from 'react'
import * as styles from '@css/pricing.module.css'
import { Title, PricingTable, Plan } from '@components'
import { useContent } from '@contexts'

export const Pricing = () => {
  const content = useContent()
  const pricing = content?.pricing || {}

  return (
    <section>
      <PricingTable>
        <Plan
          title="We'll generate 100+ avatars images of yourself !"
          subTitle="Once uploaded, the AI will start to train with your images, and you should get the first results within 6 hours. We will send you 100+ images to your email. If you have a special request you can email us and we will generate the picture for you."
          price="$10"
          style={{
            background:
              'linear-gradient(120deg, #ff00d5 0%, #6033f8 50%, #23f6ef 100%)',
          }}
          footer="One time payment. No hidden subscriptions."
        />
      </PricingTable>
    </section>
  )
}
