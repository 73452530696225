import React from 'react'
import {GalleryImgs} from '../GalleryImgs'
import * as styles from '@css/gallery.module.css'


export const Gallery = () => (
    <>
        <h4 className={styles.sectionTitle}>Unlimited styles to choose from</h4>  
        <div className={styles.gallery}>
            <GalleryImgs classes={styles.galleryItem}/>
        </div>
    </>
)
