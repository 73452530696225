import React from 'react'
import { Title } from '@components'
import * as styles from '@css/how_it_works.module.css'
import { useContent } from '@contexts'

const Step = ({ children, n }) => (
  <li className={styles.step}>
    {n && <span className={styles.stepNumber}>{n}.</span>}
    {children}
  </li>
)

export const HowItWorks = () => {
  const content = useContent()
  const howItWorks = content?.howItWorks || {}

  return (
    <section className="mx-auto" style={{ maxWidth: '800px' }}>
      <Title>
        {Highlight => (
          <>
            {howItWorks.title?.text}
            {howItWorks.title?.highlighted ? (
              <Highlight>{howItWorks.title?.highlighted}</Highlight>
            ) : null}
          </>
        )}
      </Title>
      {howItWorks.text1 && (
        <p className="large-centered-text">{howItWorks.text1}</p>
      )}
      <div className="pl-4">
        {howItWorks.text2 && (
          <p className="mt-4 font-bold text-left md:mt-12 md:text-2xl">
            {howItWorks.text2}
          </p>
        )}
        <ul className="pl-2 list-disc">
          {howItWorks.steps.map(step => (
            <Step key={step.text}>{step.text}</Step>
          ))}
        </ul>
      </div>
      <h2 className="mt-8 text-center">{howItWorks.footer?.text1}</h2>
      <h2 className="mt-4 text-lg font-bold text-center">
        {howItWorks.footer?.text2}
      </h2>
    </section>
  )
}
